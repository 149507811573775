export enum Routes {
  NETWORK = 'network',
  MESSAGES = 'messages',
  CONNECTIONS = 'connections',
  TRACING = 'tracing',
  BLOCKS = 'blocks',
  OVERVIEW = 'overview',
  WEB_NODE = 'web-node',
  LOGS = 'logs',
  PEERS = 'peers',
  WALLET = 'wallet',
  STATE = 'state',
  WALLETS = 'wallets',
  TRANSACTIONS = 'transactions',
  SNARK_POOL = 'snark-pool',
  DASHBOARD = 'dashboard',
  BLOCK = 'block',
  LIBP2P = 'libp2p',
  NODES = 'nodes',
  SCAN_STATE = 'scan-state',
  EXPLORER = 'explorer',
  PROPAGATION = 'propagation',
  SYSTEM = 'system',
  SNARK_TRACES = 'snark-traces',
  RESOURCES = 'resources',
  TOPOLOGY = 'topology',
  NEW = 'new',
  ACTIONS = 'actions',
  SNARK_WORKER = 'snark-worker',
  FRONTIER = 'frontier',
  BOOTSTRAP = 'bootstrap',
  BOOTSTRAP_STATS = 'bootstrap-stats',
  LIVE = 'live',
  WORK_POOL = 'work-pool',
  SNARKS = 'snarks',
  NODE_DHT = 'node-dht',
  BLOCK_PRODUCTION = 'block-production',
  WON_SLOTS = 'won-slots',
  MEMPOOL = 'mempool',
  LOADING_WEB_NODE = 'loading-web-node',
  OCAML = 'ocaml',
  RUST = 'rust',
  FUZZING = 'fuzzing',
  LEADERBOARD = 'leaderboard',
}
