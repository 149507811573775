@if (showLandingPage$ | async) {
  <!--  <mina-landing-page></mina-landing-page>-->
  <mina-web-node-landing-page (goToNode)="goToWebNode()"
                              (stopRequests)="clearNodeUpdateSubscription()"></mina-web-node-landing-page>
} @else if (showLoadingWebNodePage$ | async) {
  <router-outlet></router-outlet>
} @else if (showLeaderboardPage$ | async) {
  <router-outlet></router-outlet>
} @else if (loaded) {
  <mat-sidenav-container [hasBackdrop]="false"
                         class="w-100 h-100"
                         *ngIf="menu$ | async as menu">
    @if (isDesktop) {
      <mat-sidenav mode="side"
                   [class.opened]="menu.open"
                   [class.collapsed]="menu.collapsed"
                   [class.mobile]="menu.isMobile">
        <mina-menu></mina-menu>
        <div class="backdrop" (click)="toggleMenu()"></div>
      </mat-sidenav>
    }
    <mat-sidenav-content class="flex-column"
                         [style.margin-left.px]="menu.isMobile ? 0 : (menu.collapsed ? 44 : 160)">
      @if (!hideToolbar) {
        <mina-toolbar></mina-toolbar>
      }
      <div id="mina-content"
           class="overflow-hidden"
           [class.no-toolbar]="hideToolbar"
           [class.no-submenus]="subMenusLength < 2"
           [class.mobile]="menu.isMobile">
        <router-outlet></router-outlet>
      </div>
      @if (!isDesktop) {
        <mina-submenu-tabs [class.d-none]="subMenusLength < 2"
                           class="mobile-menu border-top"
                           (subMenusLength)="onSubmenusLengthChange($event)"></mina-submenu-tabs>
        <mina-menu-tabs></mina-menu-tabs>
      }
    </mat-sidenav-content>
  </mat-sidenav-container>
}
